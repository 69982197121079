// Load all the controllers within this directory and all subdirectories. 
// Controller files must be named *_controller.js.

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import Chart from "stimulus-chartjs"
import TextareaAutogrow from "stimulus-textarea-autogrow"
import ContentLoader from "stimulus-content-loader";
const application = Application.start()
const context = require.context("controllers", true, /_controller\.js$/)

application.register("chart", Chart)
application.register("textarea-autogrow", TextareaAutogrow)
application.register("content-loader", ContentLoader)
application.load(definitionsFromContext(context))


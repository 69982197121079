// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    window.ClassicEditor
    .create( this.element, {
      toolbar: [ 'heading', '|', 'bold', 'italic', 'link', '|', 'bulletedList', 'numberedList', '|', 'insertTable', 'blockQuote' ],
        heading: {
            options: [
                { model: 'paragraph', title: 'Brödtext' },
                { model: 'heading3', view: 'h3', title: 'Rubrik 3', class: 'ck-heading_heading2' },
                { model: 'heading4', view: 'h4', title: 'Rubrik 4', class: 'ck-heading_heading3' }
            ]
        },
        table: {
          contentToolbar: [
              'tableColumn', 'tableRow', 'mergeTableCells',
              'tableProperties', 'tableCellProperties'
          ],

          // Configuration of the TableProperties plugin.
          tableProperties: {
              // ...
          },

          // Configuration of the TableCellProperties plugin.
          tableCellProperties: {
              // ...
          }
      }

    } )
    .then( editor => {
        //console.log( editor );
    } )
    .catch( error => {
        console.error( error );
    } );
  }

}

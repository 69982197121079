import { Controller } from "stimulus"
import Cropper from "cropperjs"


export default class extends Controller {
  static targets = [ "crop", "output"]
 

  
  connect() {
    this.result = []
    console.log(this.outputTarget)
  }

  update(event) {
    var data = event.detail
    this.result[data["name"]] = data["crop"]
    console.log(JSON.stringify(this.result))
    this.outputTarget.innerHTML = JSON.stringify(this.result);
  }
}
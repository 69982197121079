import { Controller } from "stimulus"
import Cropper from "cropperjs"


export default class extends Controller {
  static targets = ["image"]
  static values = { 
    model: String, 
    ratio: Number, 
    name: String,
  }

  connect() {
    let _this = this
    new Cropper(this.imageTarget, {
      aspectRatio: this.ratioValue,
      minContainerWidth: 750,
      minContainerHeight: 422,
      viewMode: 2,
      crop(event) {
        _this.result = [event.detail.x, event.detail.y, event.detail.width, event.detail.height];
        // _this.result["crop"][0] = event.detail.x
        // _this.result["crop"][1] = event.detail.y
        // _this.result["crop"][2] = event.detail.width
        // _this.result["crop"][3] = event.detail.height
        // console.log(_this.result);

        // _this.crop_x().value = event.detail.x
        // _this.crop_y().value = event.detail.y
        // _this.crop_width().value = event.detail.width
        // _this.crop_height().value = event.detail.height
        _this.element.dispatchEvent(_this.event({"name": _this.nameValue, "crop": [event.detail.x, event.detail.y, event.detail.width, event.detail.height]})) 
      }
    })
  }

  event(data) {
    if (this._event == undefined) {
      this._event = document.createEvent("CustomEvent")
      this._event.initCustomEvent("cropper-updated", true, true, data)
    }
    return this._event
  }

  // crop_x() {
  //   if (this._crop_x == undefined) {
  //     this._crop_x = document.createElement("input")
  //     this._crop_x.name = `${this.modelValue}[crop_x]`
  //     this._crop_x.type = "hidden"
  //     this.imageTarget.parentNode.insertBefore(this._crop_x, this.imageTarget.nextSibling)
  //   }
  //   return this._crop_x
  // }

  // crop_y() {
  //   if (this._crop_y == undefined) {
  //     this._crop_y = document.createElement("input")
  //     this._crop_y.name = `${this.modelValue}[crop_y]`
  //     this._crop_y.type = "hidden"
  //     this.imageTarget.parentNode.insertBefore(this._crop_y, this.imageTarget.nextSibling)
  //   }
  //   return this._crop_y
  // }

  // crop_width() {
  //   if (this._crop_width == undefined) {
  //     this._crop_width = document.createElement("input")
  //     this._crop_width.name = `${this.modelValue}[crop_width]`
  //     this._crop_width.type = "hidden"
  //     this.imageTarget.parentNode.insertBefore(this._crop_width, this.imageTarget.nextSibling)
  //   }
  //   return this._crop_width
  // }

  // crop_height() {
  //   if (this._crop_height == undefined) {
  //     this._crop_height = document.createElement("input")
  //     this._crop_height.name = `${this.modelValue}[crop_height]`
  //     this._crop_height.type = "hidden"
  //     this.imageTarget.parentNode.insertBefore(this._crop_height, this.imageTarget.nextSibling)
  //   }
  //   return this._crop_height
  // }

  

}
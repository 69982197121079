// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
export default class extends Controller {
  connect() {
    this.targetId = this.element.dataset.bsTarget
    this.storedValue = localStorage.getItem(this.targetId) || 'false'
    if(this.storedValue == 'true') {
      this.element.setAttribute('aria-expanded', true)
      document.querySelectorAll(this.targetId).forEach(elm => elm.classList.add('show'))
    }
  }

  toggle() {
    localStorage.setItem(this.targetId, this.storedValue == 'true' ? 'false' : 'true')
  }
  
}

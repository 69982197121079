// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import * as bootstrap from 'bootstrap';
export default class extends Controller {
  connect() {
    const toast = new bootstrap.Toast(this.element)
    toast.show()
  }

  
}

import { Controller } from 'stimulus'
import Sortable from 'sortablejs'
import Rails from "@rails/ujs"
export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, {
      onEnd: this.end.bind(this),
      animation: 150,
      handle: '.handle',
      ghostClass: 'bg-light',
      filter: '.non-sortable',
      direction: 'vertical'
    })
  }

  end(event) {
    let id = event.item.dataset.id;
    let data = new FormData();
    let newPosition = event.newIndex + 1;
    data.append("position", newPosition)
    Rails.ajax({
      url: this.data.get('url').replace(":id", id),
      type: 'PATCH',
      data: data, 
      success() {
        console.log("Sorted:", id, "to position:", newPosition )
      }
    })
  }
}
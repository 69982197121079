import { Controller } from "stimulus"
export default class extends Controller {
  static targets = ["add_item", "template"]
  connect() { }
  add_association(event) {
    event.preventDefault()  
    const content = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, new Date().valueOf())
    this.add_itemTarget.insertAdjacentHTML('beforebegin', content)
    const newElements = this.element.querySelectorAll('.new-record');
    const addedElement = newElements[newElements.length-1];
    addedElement.dispatchEvent(new Event("nested-item-added", { bubbles: true}))
   }

  remove_association(event) {
    event.preventDefault()
    let item = event.target.closest(".nested-fields")
    item.querySelector("input[name*='_destroy']").value = 1
    item.style.display = 'none'
  }
}
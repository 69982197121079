// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
//import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
const ClassicEditor = require( '@ckeditor/ckeditor5-build-classic' );
Rails.start()
ActiveStorage.start()

import "controllers"
import '../theme/theme'
// document.addEventListener("turbo:load", function() {
//   const elements = document.getElementsByClassName('ckeditor')
//   elements.forEach(element => {
//     window.ClassicEditor
//     .create( element, {
//       toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ],
//         heading: {
//             options: [
//                 { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
//                 { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
//                 { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' }
//             ]
//         }
//     })
//     .then( editor => {
//         //console.log( editor );
//     } )
//     .catch( error => {
//         console.error( error );
//     } );
//   });
// });